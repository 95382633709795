(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/column/views/column.js') >= 0) return;  svs.modules.push('/components/banner_layouts/column/views/column.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_layouts=_svs.banner_layouts||{};
_svs.banner_layouts.column=_svs.banner_layouts.column||{};
_svs.banner_layouts.column.templates=_svs.banner_layouts.column.templates||{};
svs.banner_layouts.column.templates.column = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression((lookupProperty(helpers,"dynamicActionTemplate")||(depth0 && lookupProperty(depth0,"dynamicActionTemplate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"actionType") : depth0),(depth0 != null ? lookupProperty(depth0,"actionConfig") : depth0),(depths[1] != null ? lookupProperty(depths[1],"trackingString") : depths[1]),{"name":"dynamicActionTemplate","hash":{},"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":12,"column":75}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<article class=\"banner f-content banner-article\" data-cmsid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"bannerId") || (depth0 != null ? lookupProperty(depth0,"bannerId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bannerId","hash":{},"data":data,"loc":{"start":{"line":1,"column":61},"end":{"line":1,"column":73}}}) : helper)))
    + "\" data-cmstype=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"contentType") || (depth0 != null ? lookupProperty(depth0,"contentType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contentType","hash":{},"data":data,"loc":{"start":{"line":1,"column":89},"end":{"line":1,"column":104}}}) : helper)))
    + "\"\n  data-impression=\"true\" data-impression-tracking=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"trackingString") || (depth0 != null ? lookupProperty(depth0,"trackingString") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"trackingString","hash":{},"data":data,"loc":{"start":{"line":2,"column":51},"end":{"line":2,"column":69}}}) : helper)))
    + "\">\n  <div class=\"banner-article-media\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-image_v2-image"),(depth0 != null ? lookupProperty(depth0,"image") : depth0),{"name":"components-content-image_v2-image","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n  <div class=\"banner-article-content\">\n    <h2 class=\"h1\" itemprop=\"headline\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":7,"column":39},"end":{"line":7,"column":48}}}) : helper)))
    + "</h2>\n    <p itemprop=\"text\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"body") || (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"body","hash":{},"data":data,"loc":{"start":{"line":8,"column":23},"end":{"line":8,"column":31}}}) : helper)))
    + "</p>\n    <div class=\"banner-article-bottom\">\n      <div class=\"banner-article-action\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"callToActions") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":13,"column":17}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n  </div>\n</article>\n";
},"usePartial":true,"useData":true,"useDepths":true});
Handlebars.partials['components-banner_layouts-column-column'] = svs.banner_layouts.column.templates.column;
})(svs, Handlebars);


 })(window);